<script setup lang="ts">
import { CardPostNew, ICardPostNewProps } from "@frontiers-vue3/public-pages";

interface ICardGridHomeHomeProps {
  cards: Array<ICardPostNewProps>;
  type?: "Primary" | "Secondary";
}

const props = withDefaults(defineProps<ICardGridHomeHomeProps>(), {
  cards: () => [],
  type: "Secondary"
});

const classList = computed((): Array<string> => {
  const classes = ["CardGridHome"];
  classes.push(`CardGridHome--${props.type.toLowerCase()}`);
  return classes;
});
</script>

<template>
  <div :class="classList">
    <CardPostNew v-for="(card, i) in cards" :key="i" v-bind="card" />
  </div>
</template>

<style lang="scss">
.CardGridHome {
  display: grid;
  gap: var(--layout-gap);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  & > * {
    grid-area: span;
  }

  &--primary {
    grid-template-columns: repeat(2, 1fr);

    @media #{$break-xs} {
      grid-template-columns: repeat(1, 1fr);
    }

    & > *:first-child {
      grid-column: 1/3;

      @media #{$break-xs} {
        grid-area: span;
      }
    }
  }

  &--secondary {
    @media #{$break-laptop} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$break-md} {
      grid-template-columns: repeat(1, 1fr);
    }

    .Card {
      .Description {
        display: none;
      }

      &__link {
        @media #{$break-sm} {
          flex-direction: row;
        }

        @media #{$break-xs} {
          flex-direction: column;
        }
      }

      &__img {
        @media #{$break-sm} {
          width: var(--layout-col-6);
        }

        @media #{$break-xs} {
          width: 100%;
        }
      }

      &__body {
        @media #{$break-xs} {
          margin-bottom: 32px;
        }
      }

      @media #{$break-only-md} {
        &__link {
          flex-direction: row;
        }

        &__img {
          width: var(--layout-col-9);

          @media #{$break-xs} {
            width: var(--layout-col-6);
          }
        }
      }
    }
  }
}
</style>
